
import React from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Container, Avatar, Typography, Box, TextField, Button, Snackbar, Checkbox, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { userService } from './services/user_functions';
import { Link } from 'react-router-dom'

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(0.8),
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  cancelButton: {
    margin: theme.spacing(0, 0, 1),
    backgroundColor: '#ff0000',
    color: '#ffffff'
  },
})

const typeLocal = [{value:'H',label:'Hospital'},{value:'C',label:'Centro de Saúde'}];

class NewEmployee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      type: '',
      password: '',
      code: '',
      sgcode: '',
      email1: '',
      email2: '',
      email3: '',
      drs: '',
      city: '',
      openClose: false,
      //message: 'Houve um erro na conexão com o sistema. Tente novamente mais tarde.',
    }
  }
  createHospital = () => {
    if (!this.state.password || !this.state.code || !this.state.sgcode || !this.state.name || !this.state.type || !this.state.email1 || !this.state.drs || !this.state.city) {
      this.setState({ message: 'Um ou mais campos estão em branco.', openClose: true })
    }
    else {
      userService.createHospital(this.state.password, this.state.code, this.state.sgcode, this.state.name, this.state.type, this.state.email1,
        this.state.email2, this.state.email3, this.state.drs, this.state.city).then(response => {
          if (response.status === 200) {
            this.props.history.push('/Admin')
          }
          else {
            this.setState({ openClose: true })
          }
        }).catch(e => {
          if (e.response) {
            if (e.response.status >= 500) { this.setState({message:'Houve um erro na conexão com o sistema. Tente novamente mais tarde.', openClose: true })}
            else if (e.response.status === 401) { this.setState({ message: "Usuário não tem permissão para editar Local de Coleta", openClose: true }) }
            else if (e.response.status === 403) { this.setState({ message: "Informe uma senha forte: Entre 8 e 20 caracteres, incluindo números, letras maiúsculas e minúsculas e caracteres especiais.", openClose: true }) }
            else if (e.response.status === 406) { this.setState({ message: "E-mail inválido", openClose: true }) }
            else if (e.response.status === 409) { this.setState({ message: "Já existe esse código + tipo ou sgcode.", openClose: true }) }
            else if (e.response.status === 400) { this.setState({ message: "Código deve ser numérico.", openClose: true }) }
          }
          else {
             this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: true })
          }
        })
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs" className={classes.paper}>
        <Avatar className={classes.avatar}><PersonAddIcon /></Avatar>
        <Typography component="h3" variant="h6" >Insira os dados do Local de Coleta:</Typography>
        <Box className={classes.form} method="post">
          <TextField size="small" variant="outlined" required fullWidth label="Código" autoFocus className={classes.text} onChange={e => this.setState({ code: e.target.value })} inputProps={{ maxLength: 10, type:'number', min:1, max:9999}} />
          <TextField size="small" variant="outlined" required fullWidth label="Código SG" className={classes.text} onChange={e => this.setState({ sgcode: e.target.value })} inputProps={{ maxLength: 10 }}/>
          <TextField size="small" variant="outlined" required fullWidth label="Nome do Local de Coleta" className={classes.text} onChange={e => this.setState({ name: e.target.value })} inputProps={{ maxLength: 100 }}/>
          <TextField size="small" select variant="outlined" required fullWidth id="label-hospital" label="Tipo do Local de Coleta" className={classes.text} onChange={e => this.setState({ type: e.target.value })} >
                  {typeLocal.map((option) => 
                    (<option key={option.value} value={option.value}>
                      {option.label}
                    </option>))}
          </TextField>
          <TextField size="small" variant="outlined" required fullWidth label="Município" className={classes.text} onChange={e => this.setState({ city: e.target.value })} inputProps={{ maxLength: 100 }} />
          <TextField size="small" variant="outlined" required fullWidth label="DRS" className={classes.text} onChange={e => this.setState({ drs: e.target.value })} inputProps={{ maxLength: 100 }} />
          <TextField size="small" variant="outlined" required fullWidth label="Email 1" className={classes.text} onChange={e => this.setState({ email1: e.target.value })} inputProps={{ maxLength: 100 }}/>
          <TextField size="small" variant="outlined" fullWidth label="Email 2" className={classes.text} onChange={e => this.setState({ email2: e.target.value })} inputProps={{ maxLength: 100 }}/>
          <TextField size="small" variant="outlined" fullWidth label="Email 3" className={classes.text} onChange={e => this.setState({ email3: e.target.value })} inputProps={{ maxLength: 100 }}/>
          <TextField size="small" variant="outlined" required fullWidth label="Crie uma senha" type={(this.state.showPassword ? "text" : "password")} className={classes.text} onChange={e => this.setState({ password: e.target.value })} inputProps={{ maxLength: 20 }} />
          
          <FormControlLabel control={<Checkbox checked={this.state.showPassword} onChange={e => this.setState({showPassword: !this.state.showPassword})} />} label="Mostrar nova senha" />

          <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.createHospital}>Criar hospital</Button>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="Admin"><Button fullWidth variant="contained"className={classes.cancelButton}>Cancelar</Button></Link>
        </Box>
        <Snackbar className={classes.alertMessage} open={this.state.openClose} autoHideDuration={3000} onClose={e => this.setState({ openClose: false })} message={this.state.message} />
      </Container>)

  }
}

export default withStyles(useStyles)(NewEmployee)