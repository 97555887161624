
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom'
import { Box, Container, TextField,  Button, Backdrop, Avatar, Snackbar, Checkbox, FormControlLabel } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { userService } from './services/user_functions';
import Loading from './Loading';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { authenticationService } from './services/auth_service';
import { LaptopWindows } from '@material-ui/icons';


/* ESSA PARTE DO CÓDIGO FOI TIRADA DE https://material-ui.com/components/tables/ COM ALTERAÇÕES */




function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {


  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//para alterar colunas da tabela, altere os cabeçalhos aqui
const headCells = [
  { id: 'code', numeric: false, disablePadding: false, label: 'Código' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'sgcode', numeric: false, disablePadding: false, label: 'Código_SG' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Local_Coleta' },
  { id: 'city', numeric: false, disablePadding: false, label: 'Cidade' },
  { id: 'drs', numeric: false, disablePadding: false, label: 'DRS' },
  { id: 'email1', numeric: false, disablePadding: false, label: 'Email1' },
  { id: 'email2', numeric: false, disablePadding: false, label: 'Email2' },
  { id: 'email3', numeric: false, disablePadding: false, label: 'Email3' },
  { id: 'first_access', numeric: false, disablePadding: false, label: 'Primeiro Acesso' },
  { id: 'user.accept_terms', numeric: false, disablePadding: false, label: 'Aceite' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="right"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >

              <b>{headCell.label}</b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding='checkbox'><b>Editar</b></TableCell>
        <TableCell padding='checkbox'><b>Apagar</b></TableCell>
        <TableCell padding='checkbox'><b>Enviar_senha</b></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTable(props) {
  const { rows, history, callBack } = props

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('ordering');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  if (rows === []) return (<Loading />)
  else {
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };



    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleSendPassword = (id) => {
      // if (!row.email1 && !'email2' && !'email3') {
      //   window.alert('Local de coleta não possui nenhum e-mail para envio. Cadastre um email e tente novamente.')
      // }
      // else {
      if (window.confirm("Tem certeza que deseja recriar e enviar a senha para esse local de coleta?")) {
        // Admin.send_staff_password()
        // history.push("/Admin");
        // history.go();
        userService.send_staff_password(id).then(response => {
          if (response.status === 200){
            window.alert("Senha recriada e enviada com sucesso para o local de coleta " + response.data)
            history.push("/Admin");
            history.go();
          }
          else {
            window.alert("Não foi possivel recriar e enviar a senha para o local de coleta")
          }
        })
        .catch(e => {
          if (e.response) {
            if (e.response.status >= 500) {(window.alert("Houve um erro na conexão com o sistema. Tente novamente mais tarde."))}
            else if (e.response.status === 401) {(window.alert("Usuário não tem permissão para recriar senha para o Local de Coleta"))}
            else if (e.response.status === 406) {(window.alert("Não foi possível gerar/alterar a senha do local de coleta"))}
            else if (e.response.status === 404) {(window.alert("Local de Coleta/ Usuário não encontrado. Ou não possui email para enviar"))}
            else if (e.response.status === 409) {(window.alert("Senha recriada para o Local de coleta porém não foi possivel enviar email. Verifique."))}
          }
          else {
            window.alert("Houve um erro na conexão com o sistema. Tente novamente mais tarde.")
         }
     
         })
      }
    // }
    }

    const handleDelete = (id) => {
      if (window.confirm("Você deseja apagar esse registro?")) {
        userService.deleteHospital(id).then(response => {
          history.push("/Admin");
          history.go();

        })
      }
    }
    const handleUpdate = (rowData) => {
      callBack(true, rowData);
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    //para alterar quais valores serão exibidos, alterar em <TableCell>
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >


                        <TableCell component="th" align="right">{row.code}</TableCell>
                        <TableCell align="right">{row.type}</TableCell>
                        <TableCell align="right">{row.sgcode}</TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.city}</TableCell>
                        <TableCell align="right">{row.drs}</TableCell>
                        <TableCell align="right">{row.email1}</TableCell>
                        <TableCell align="right">{row.email2}</TableCell>
                        <TableCell align="right">{row.email3}</TableCell>
                        <TableCell align="right">
                          <Checkbox checked={row.first_access} size="small"/>
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox checked={row.user.accept_terms} size="small"/>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Button><EditIcon onClick={() => handleUpdate(row)} /></Button>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Button><DeleteIcon onClick={() => handleDelete(row.id)} /></Button>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Button><SendIcon onClick={() => handleSendPassword(row.id)} /></Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination labelDisplayedRows={({ from, to, count }) => `Mostrando ${from}-${to} de ${count} itens.`}
            labelRowsPerPage="Itens por página:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>

      </div>
    );
  }
}

const useStyles2 = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },

  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  filtrosHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

  },
  table: {
    width: '100%',
    alignItems: 'center',
  },
  tableitem: {
    width: '15%'

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(0.8),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  cancelButton: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#ff0000',
    color: '#ffffff'
  },
});

const typeLocal = [{value:'H',label:'Hospital'},{value:'C',label:'Centro de Saúde'}];
const typeFirstAccess = [{value: '',label:''},
                         {value: true,label:'Sim'},
                         {value: false,label:'Não'}];
const typeAcceptTerms = [{value: '',label:''},
                         {value: true,label:'Sim'},
                         {value: false,label:'Não'}];

class Admin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentUser: this.props.userData,
      rows: [],
      row: null,
      password: '',
      filterName: '',
      filterCode: '',
      filterEmail: '',
      filterSGCode: '',
      filterCidade: '',
      filterDRS: '',
      filterFirstAccess: '',
      filterAcceptTerms: '',
      openEdit: false,
      openClose: false,
      message: "Verifique os campos e tente novamente.",
      showPassword: false
    }
  }

  limparFiltros = () => {
        
     document.getElementById('filterCode').value = ""
     document.getElementById('filterSGCode').value = ""
     document.getElementById('filterName').value = ""
     document.getElementById('filterEmail').value = ""
     document.getElementById('filterCidade').value = ""
     document.getElementById('filterDRS').value = ""
     document.getElementById('filterFirstAccess').value = ""
     document.getElementById('filterAcceptTerms').value = ""
 
     setTimeout(() => {
      this.setState({
        filterCode: '',
        filterSGCode: '',
        filterName: '',
        filterEmail: '',
        filterCidade: '',
        filterDRS: '',
        filterFirstAccess: '',
        filterAcceptTerms: ''});
        this.listHospitals();
      }, 10)
    }


  listHospitals = () => {

    userService.listHospitals(this.state.filterCode, this.state.filterName, this.state.filterEmail, this.state.filterDRS, this.state.filterCidade, this.state.filterSGCode, this.state.filterFirstAccess, this.state.filterAcceptTerms).then(response => {

      this.setState({ rows: response.data });

    }
    ).catch(e => {
      window.alert("A sua sessão expirou. Entre com seus dados novamente.")
      authenticationService.logout();
      this.props.history.push("/");
    });
  }
  componentDidMount() {
    this.listHospitals()
  }

  handleCallBack = (editDelete, value) => {
    if (editDelete) {
      this.setState({ openEdit: true })
      this.setState({ row: { ...value } })

    }
  }
  updateHospital = () => {
    if (!this.state.row || !this.state.row.code || !this.state.row.name || !this.state.row.type || !this.state.row.email1 || !this.state.row.city || !this.state.row.drs) {
      this.setState({ message: 'Um ou mais campos estão em branco.', openClose: true })
    }
    else {
      userService.updateHospital(Object.assign(this.state.row, {"password": this.state.password})).then(response => {
        if (response.status === 200) {
          this.setState({ message: "Local de coleta alterado com sucesso.", openClose: true })
          this.props.history.push('/Admin')
          this.props.history.go();
        }
        else {
          this.setState({ message: "Verifique seu login e senha e tente novamente.", openClose: true })
        }
      }).catch(e => {
        if (e.response) {
          if (e.response.status >= 500) { this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: true })}
          else if (e.response.status === 401) { this.setState({ message: "Usuário não tem permissão para editar Local de Coleta", openClose: true }) }
          else if (e.response.status === 403) { this.setState({ message: "Informe uma senha forte: Entre 8 e 20 caracteres, incluindo números, letras maiúsculas e minúsculas e caracteres especiais.", openClose: true }) }
          else if (e.response.status === 406) { this.setState({ message: "E-mail inválido", openClose: true }) }
          else if (e.response.status === 404) { this.setState({ message: "Local de Coleta não encontrado", openClose: true }) }
          else if (e.response.status === 409) { this.setState({ message: "Já existe esse código + tipo ou sgcode.", openClose: true }) }
        }
        else {
           this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: true })
        }
      })
    }
  }

   

  render() {
    const { classes } = this.props
    return (
      <Container >
        <Typography variant="h6" className={classes.grid}>Bem vindo(a),  {this.state.currentUser.name}</Typography>

        <Container maxWidth="lg">
          <ExpansionPanel className={classes.grid}>
            <ExpansionPanelSummary className={classes.filtrosHeader}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant='button' className={classes.heading}><b>Filtros</b></Typography>
            </ExpansionPanelSummary>
            <table className={classes.table}>
            <thead>
            <tr>
                <td align="right" className={classes.tableitem}><Typography>Código:</Typography></td>
                <td className={classes.tableitem}><TextField id='filterCode' onChange={e => this.setState({ filterCode: e.target.value })} inputProps={{ maxLength: 10 }} /></td>
                <td align="right" className={classes.tableitem}><Typography >Cidade:</Typography></td>
                <td className={classes.tableitem}><TextField id='filterCidade' onChange={e => this.setState({ filterCidade: e.target.value })} inputProps={{ maxLength: 100 }}/></td>
                <td></td>
                <td></td>
             </tr>

            <tr>
                <td align="right" className={classes.tableitem}><Typography >Código SG:</Typography></td>
                <td className={classes.tableitem}><TextField id='filterSGCode' onChange={e => this.setState({ filterSGCode: e.target.value })} inputProps={{ maxLength: 10 }}/></td>
                <td align="right" className={classes.tableitem}><Typography >DRS:</Typography></td>
                <td className={classes.tableitem}><TextField id='filterDRS' onChange={e => this.setState({ filterDRS: e.target.value })} inputProps={{ maxLength: 100 }} /></td>
                <td align="right" className={classes.tableitem}><Typography >Primeiro acesso:</Typography></td>
                <td className={classes.tableitem}><TextField size="small" select variant="outlined" required fullWidth id="filterFirstAccess"   className={classes.text}  onChange={e => this.setState({ filterFirstAccess: e.target.value })} SelectProps={{native: true,}} >
                                                            {typeFirstAccess.map((option) => 
                                                              (<option key={option.value} value={option.value}>
                                                                {option.label}
                                                              </option>))}
                                                </TextField>
                </td>

            </tr>

            <tr>
               <td align="right" className={classes.tableitem}><Typography >Local de Coleta:</Typography></td>
                <td className={classes.tableitem}><TextField id='filterName' onChange={e => this.setState({ filterName: e.target.value })} inputProps={{ maxLength: 100 }}/></td>
                <td align="right" className={classes.tableitem}><Typography >Email:</Typography></td>
                <td className={classes.tableitem}><TextField id='filterEmail' onChange={e => this.setState({ filterEmail: e.target.value })} inputProps={{ maxLength: 100 }}/></td>
                <td align="right" className={classes.tableitem}><Typography >Aceite Termo:</Typography></td>
                <td className={classes.tableitem}><TextField size="small" select variant="outlined" required fullWidth id="filterAcceptTerms"   className={classes.text}  onChange={e => this.setState({ filterAcceptTerms: e.target.value })} SelectProps={{native: true,}} >
                                                            {typeAcceptTerms.map((option) => 
                                                              (<option key={option.value} value={option.value}>
                                                                {option.label}
                                                              </option>))}
                                                </TextField>
                </td>
            </tr>

              <tr><td colSpan="2" align="right"><Button variant="contained" onClick={this.listHospitals}>FILTRAR</Button></td>
                  <td colSpan="0" align="right"><Button variant="contained" onClick={() => {
                      this.limparFiltros();
                  }}>LIMPAR BUSCA</Button></td>
              </tr>
            </thead>
            </table>
          </ExpansionPanel>
          <EnhancedTable rows={this.state.rows} history={this.props.history} callBack={this.handleCallBack} />
          <Link style={{ textDecoration: 'none', color: 'white' }} to="FuncionarioNovo"><Button variant="contained" >Inserir Local de Coleta</Button></Link>
        </Container>

        <Backdrop className={classes.backdrop} open={this.state.openEdit}>
          <Paper>
            <Container component="main" maxWidth="xs" className={classes.paper}>
              <Avatar className={classes.avatar}><PersonAddIcon /></Avatar>
              <Typography component="h3" variant="h6" >Editando Local de Coleta:</Typography>
              <Box className={classes.form} method="post">
                <TextField size="small" variant="outlined" required fullWidth label="Código" value={this.state.row === null ? '' : this.state.row.code} autoFocus className={classes.text} onChange={e => { var x = this.state.row; x.code = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 10, type:'number', min:1, max:9999}}  />
                <TextField size="small" variant="outlined" required fullWidth label="Código SG" value={this.state.row === null ? '' : this.state.row.sgcode} autoFocus className={classes.text} onChange={e => { var x = this.state.row; x.sgcode = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 10 }}/>
                <TextField size="small" variant="outlined" required fullWidth label="Local de Coleta" value={this.state.row === null ? '' : this.state.row.name} className={classes.text} onChange={e => { var x = this.state.row; x.name = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 100 }} />
                <TextField size="small" select variant="outlined" required fullWidth id="label-hospital" label="Tipo do Local de Coleta" value={this.state.row === null ? '' : this.state.row.type}  className={classes.text} onChange={e => { var x = this.state.row; x.type = e.target.value; this.setState({ row: x }) }} SelectProps={{native: true,}}>
                  {typeLocal.map((option) => 
                    (<option key={option.value} value={option.value}>
                      {option.label}
                    </option>))}
                </TextField>
                <TextField size="small" variant="outlined" required fullWidth label="Município" value={this.state.row === null ? '' : this.state.row.city} className={classes.text} onChange={e => { var x = this.state.row; x.city = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 100 }} />
                <TextField size="small" variant="outlined" required fullWidth label="DRS" value={this.state.row === null ? '' : this.state.row.drs} className={classes.text} onChange={e => { var x = this.state.row; x.drs = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 100 }} />
                <TextField size="small" variant="outlined" required fullWidth label="Email 1" value={this.state.row === null ? '' : this.state.row.email1} className={classes.text} onChange={e => { var x = this.state.row; x.email1 = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 100 }} />
                <TextField size="small" variant="outlined" fullWidth label="Email 2" value={this.state.row === null ? '' : this.state.row.email2} className={classes.text} onChange={e => { var x = this.state.row; x.email2 = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 100 }} />
                <TextField size="small" variant="outlined" fullWidth label="Email 3" value={this.state.row === null ? '' : this.state.row.email3} className={classes.text} onChange={e => { var x = this.state.row; x.email3 = e.target.value; this.setState({ row: x }) }} inputProps={{ maxLength: 100 }}  />
                <TextField size="small" variant="outlined" required fullWidth label="Crie uma senha" type={(this.state.showPassword ? "text" : "password")} className={classes.text} onChange={e => this.setState({ password: e.target.value })} inputProps={{ maxLength: 20 }}  />
                
                <FormControlLabel control={<Checkbox checked={this.state.showPassword} onChange={e => this.setState({showPassword: !this.state.showPassword})} />} label="Mostrar nova senha" />

                <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.updateHospital}>Alterar</Button>
                <Button fullWidth variant="contained" className={classes.cancelButton} onClick={e => this.setState({ openEdit: false })}>Cancelar</Button>
              </Box>
              <Snackbar className={classes.alertMessage} open={this.state.openClose} autoHideDuration={3000} onClose={e => this.setState({ openClose: false })} message={this.state.message} />
            </Container>
          </Paper>
        </Backdrop>
      </Container>

    )
  }
}

export default withStyles(useStyles2)(Admin);
