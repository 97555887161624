import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockIcon from '@material-ui/icons/Lock';
import { Box, Snackbar, Checkbox, FormControlLabel } from '@material-ui/core';
import { userService } from './services/user_functions';
import { history } from './services/history'



const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  text: {
      marginTop: theme.spacing(1)
  },
})
class UpdateAdminPassword extends React.Component {
    constructor(props) {
  
      super(props);
      this.state = {
        email: '',
        currentpassword: '',
        newpassword: '',
        openClose: false,
        showPassword: false,
        message: "Verifique seu login e senha e tente novamente."
      }

    }
    tryUpdateAdminPassword = () => {
        userService.updateAdminPassword(this.state.email, this.state.currentpassword, this.state.newpassword)
          .then(result => {
            if (result.status === 200) {
              window.alert("Senha alterada com sucesso.")
              history.push("/LoginFuncionario")
            }
            else {
              this.setState({ message: "Verifique os dados preenchidos ou tente novamente mais tarde.", openClose: true })
            }
   
    
          }).catch(e => {
            if (e.response) {
              if (e.response.status === 403) 
              {this.setState({ message: "Informe uma senha forte: Entre 8 e 20 caracteres, incluindo números, letras maiúsculas e minúsculas e caracteres especiais." }) 
              this.setState({ openClose: true });}
            else if (e.response.status === 406) 
              {this.setState({ message: "Informe uma senha diferente da atual."}) 
               this.setState({ openClose: true });}
            else if (e.response.status === 404) 
               {this.setState({ message: "Campos Login ou senha atual não conferem." })
               this.setState({ openClose: true });}
            else if (e.response.status >= 500) {
              this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", 
              openClose: true })}
            }
          else {
            this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", 
            openClose: true })
          }
        })
    }
    render() {
        const { classes } = this.props;
        if (this.props.userData){
        //  if (this.props.userData.is_superuser)
        //      history.push("/Admin")
        //  else if (this.props.userData.is_staff)
        //      history.push("/AlterarSenhaFuncionario")
        //  else history.push("/Familia")
      }
      //else 
        return (
          <Container component="main" maxWidth="xs" className={classes.paper}>
            <Avatar className={classes.avatar}><LockIcon /></Avatar>
            <Typography component="h3" variant="h5" >Trocar a senha - usuário adm:</Typography>
            <Box className={classes.form} method="post">
            <TextField variant="outlined" className={classes.text} required fullWidth id="email" label="Login" name="email" autoFocus onChange={e => this.setState({email: e.target.value})} />
            <TextField variant="outlined" className={classes.text} required fullWidth id="currentpassword" label="Senha Atual" name="currentpassword" type={(this.state.showPassword ? "text" : "password")} autoComplete="current-password" onChange={e => this.setState({currentpassword: e.target.value})} />
            <TextField variant="outlined" className={classes.text} required fullWidth id="newpassword" label="Nova Senha " name="newpassword" type={(this.state.showPassword ? "text" : "password")} autoComplete="current-password" onChange={e => this.setState({newpassword: e.target.value})} />
            <FormControlLabel control={<Checkbox checked={this.state.showPassword} onChange={e => this.setState({showPassword: !this.state.showPassword})} />} label="Mostrar senha" />

            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.tryUpdateAdminPassword}>Alterar Senha de Admin</Button>
              
            </Box>
            <Snackbar className={classes.alertMessage} open={this.state.openClose} autoHideDuration={3000} onClose={this.handleClose} message={this.state.message} />
          </Container>
      )
  
    }

    
}
export default withStyles(useStyles)(UpdateAdminPassword);