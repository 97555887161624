import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Container, Typography, Box } from '@material-ui/core';
import LogoUnicamp from './logos/logo_unicamp.png'
import { history } from './services/history'


const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        margin: theme.spacing(3),
        /*width: '100%',*/
    },
})

class Home extends React.Component {

    render() {
        const { classes } = this.props;
        if (this.props.userData){
            if (this.props.userData.is_superuser)
                history.push("/Admin")
            else if (this.props.userData.is_staff)
                history.push("/Funcionario")
            else history.push("/Familia")
        }
        else
        return (
            <Container >
                <Box className={classes.paper}>
                    <Box marginCenter="75px"><img src={LogoUnicamp} width="150px" height="75px" alt="" /></Box>
                    <Typography variant="h5"><center>Serviço de Referência em Triagem Neonatal - SRTN/Unicamp</center></Typography>
                    <br></br>
                    {/* Para desabilitar botão familia comentar a linha abaixo */}
                    {/* <Link style={{ textDecoration: 'none', color: 'white' }} to="/LoginFamilia"><Button color="primary" variant="contained" className={classes.button}>Família</Button></Link> */}
                    <Link style={{ textDecoration: 'none', color: 'white' }} to="/LoginFuncionario"><Button color="primary" variant="contained" className={classes.button}>Instituição</Button></Link>
                    <Typography><a target="_blank" rel="noopener noreferrer" href='https://drive.google.com/drive/folders/1sg7uKSmXWQY3RbROEA4HIPeSk2iw9MEp?usp=sharing'>
                    Instruções de Acesso<br></br><center><VideocamIcon/></center> </a></Typography>    
                </Box>
            </Container>);
    }

}
export default withStyles(useStyles)(Home);
