import React from 'react';
import LogoUnicamp from './logos/logo_unicamp.png'
import { Container, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
})
class NotConnected extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Container >
                <Box className={classes.paper}>
                    <Box marginLeft="75px"><img src={LogoUnicamp} width="200px" height="100px" alt="" /></Box>
                    <Typography variant="h4">Serviço de Referência em Triagem Neonatal - SRTN/Unicamp:</Typography>
                    <Typography variant="h4" style={{ color: '#ff0000' }}>Não foi possível entrar em contato com o servidor. Tente novamente mais tarde.</Typography>
                </Box>
            </Container>)
    }
}

export default withStyles(useStyles)(NotConnected)