import { authHeader, authHeaderPDF } from './auth_header';
import { apiPath } from './const'
import Axios from "axios";

export const userService = {
    getUserInfo,
    listHospitals,
    createHospital,
    deleteHospital,
    updateHospital,
    listResults,
    listMyResults,
    testConnection,
    // updatePassword, // Para desabilitar familia comentar essa função
    updateEmployeePassword,
    updateAdminPassword,
    downloadFile,
    updateAcceptTerms,
    send_staff_password
};
const requestOptions = { headers: authHeader() };
function getUserInfo() {
    //gets name, CPF, etc from current logged user
    return Axios.get(`${apiPath}/users/me`, requestOptions);
}

function listHospitals(code, name, email, drs, city, sgcode, first_access, accept_terms) {
    //filter hospitals, parameter ignored if empty
    return Axios.get(`${apiPath}/hospitals/?code=${code}&name=${name}&email=${email}&sgcode=${sgcode}&drs=${drs}&city=${city}&first_access=${first_access}&accept_terms=${accept_terms}`, requestOptions)    

}
function createHospital(password, code, sgcode, name, type, email1, email2, email3, drs, city) {
    return Axios.post(`${apiPath}/hospitals/`, {
        "code": code,
        "sgcode": sgcode,
        "name": name,
        "type": type,
        "email1": email1,
        "email2": email2,
        "email3": email3,
        "drs": drs,
        "city": city,
        "password": password
    }, requestOptions)
}

function deleteHospital(id) {
    //delete hospital by id
    return Axios.delete(`${apiPath}/hospitals/${id}`, requestOptions)
}

function updateHospital(row) {
    //update hospital, receives the entire element as parameter
    return Axios.put(`${apiPath}/hospitals/${row.id}`, row, requestOptions)
}


function send_staff_password(id){
    return Axios.get(`${apiPath}/hospitals/${id}`, requestOptions)
}

function listResults(cns, cpf, dnv, firstName, lastName, birthday, birthPlace, collPlace, collDay, CodLCol, PDF_ImageDate) {
    //filter results, parameter ignored if empty
    return Axios.get(`${apiPath}/results/?dnv=${dnv}&cns=${cns}&cpf=${cpf}&data_nasc=${birthday}&data_coleta=${collDay}&hospital_nasc=${birthPlace}&local_coleta=${collPlace}&mother_firstname=${firstName}&mother_surname=${lastName}&cod_lcol=${CodLCol}&data_emissao=${PDF_ImageDate}`, requestOptions)
}
function listMyResults(cpf) {
    //list results linked to a CPF
    if (cpf)
        return Axios.get(`${apiPath}/results/?cpf=${cpf}`, requestOptions)
    else
        return Promise.resolve("ERRO");
}

function testConnection() {
    //tests if server is up
    return Axios.get(`${apiPath}/`)
}

// Para desabilitar familia comentar essa função
// function updatePassword(cpf, dnv, cns, birthdate, newpassword) {
//     //se quisermos usar a variação de troca de senha q aceita DNV ou CNS alterar a rota para /change_password_variant/
//     return Axios.put(`${apiPath}/users/`,
//     {"cpf":cpf, "dnv":dnv, "cns": cns, "birthdate": birthdate, "newpassword": newpassword},
//     requestOptions)

// }

function updateEmployeePassword(sgcode, currentpassword, newpassword) {
    return Axios.put(`${apiPath}/users_hospital/`, 
    {"sgcode":sgcode, "currentpassword":currentpassword, "newpassword": newpassword},
    requestOptions)
}

function updateAdminPassword(email, currentpassword, newpassword) {
    return Axios.put(`${apiPath}/users_admin/`, 
    {"email":email, "currentpassword":currentpassword, "newpassword": newpassword},
    requestOptions)
}

function downloadFile(filePath) {
    //return Axios.get(`${apiPath}${filePath}`, {responseType: 'arraybuffer', headers: authHeaderPDF()})
    return Axios.get(`${apiPath}/pdf/${filePath}`, {responseType: 'arraybuffer', headers: authHeaderPDF()})
}

//function updateAcceptTerms(username) {
function updateAcceptTerms(username) {
    // atualiza aceite de termos para sim
    console.log("Esse e o username", username)
    return Axios.put(`${apiPath}/user_accept_terms/`, 
    {"cpf":username},
    {"email":username},
    {"login":username}, 
    requestOptions)
}