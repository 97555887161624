import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockIcon from '@material-ui/icons/Lock';
import { Box, Snackbar, Checkbox, FormControlLabel, Backdrop } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { history } from './services/history'
import { authenticationService } from './services/auth_service'
import Terms from './Terms';
import { userService } from './services/user_functions';
import { Link } from 'react-router-dom'

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  text: {
    marginTop: theme.spacing(0.8),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
});



class EmployeeLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      openClose: false,
      redirectAdmin: false,
      redirectEmp: false,
      showPassword: false,
      accept_terms: false,
      openEdit: false
    }
  }
  updateLogin = (event) => {
    this.setState({ login: event.target.value });
  }
  updatePassword = (event) => {
    this.setState({ password: event.target.value });
  }
  handleShowPassword = () => {
    this.setState({ showPassword: !(this.state.showPassword) })
  }
  tryLogin = () => {
    authenticationService.loginFunc(this.state.login, this.state.password)
      .then(result => {
        if (result.status === 200) {
          if (result.data.user_data.is_superuser) {
            history.push(`/Admin`)
          }
          else history.push(`/Funcionario`)
        }
        else {
          this.setState({ message: "Verifique seu login e senha e tente novamente.", openClose: true })
        }
      }).catch(e => {
        if (e.response) {
        if (e.response.status === 403) 
          {this.setState({ message: "Para utilizar o sistema você precisa ler e concordar com os termos de uso." }) 
           this.setState({ openEdit: true });}
        else if (e.response.status === 401) 
          {this.setState({ message: "Verifique seu login e senha e tente novamente."}) 
           this.setState({ openClose: true });}
        else if (e.response.status >= 500) 
          {this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: true })}
        this.setState({ openClose: true });
      }
      else {
        this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: false })
      }
    })
  }

  updateAcceptTerms = () => {
    userService.updateAcceptTerms(this.state.login) //para funcionarios é login
    .then(result => {
      if (result.status === 200) {
        this.setState({ message: "Você aceitou os termos de uso. Entre com login e senha novamente.", openEdit: false })
        this.tryLogin()
      }
    }).catch(e => {
      if (e.response) {
        if (e.response.status >= 500) 
        {this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: true })}
      this.setState({ openClose: true });
      }
      else {
        this.setState({ message: "Houve um erro na conexão com o sistema. Tente novamente mais tarde.", openClose: false })
      }
    })
  }

handleCallBack = (edit, value) => {
   if (edit) {
     this.setState({ openEdit: true })
     this.setState({ row: { ...value } })
   }
 }

render() {
  const { classes } = this.props;
  if (this.props.userData){
      if (this.props.userData.is_superuser)
          history.push("/Admin")
      else if (this.props.userData.is_staff)
          history.push("/Funcionario")
      else history.push("/Familia")
  }
  else
    if (this.state.redirectAdmin) return (<Redirect to={{ pathname: '/Admin' }} />);
    if (this.state.redirectEmp) return (<Redirect to={{ pathname: '/Funcionario' }} />);
    return (
      <Container>
        <Container component="main" maxWidth="xs" className={classes.paper}>
            <Avatar className={classes.avatar}><LockIcon /></Avatar>
            <Typography component="h3" variant="h5">Insira o seu login e senha:</Typography>
            <Box className={classes.form}  method="post">
              <TextField variant="outlined" required fullWidth id="login" label="Login" name="login" autoFocus className={classes.text} onChange={this.updateLogin} />
              <TextField variant="outlined" required fullWidth id="password" label="Senha" name="password" className={classes.text} type={(this.state.showPassword ? "text" : "password")} autoComplete="current-password" onChange={this.updatePassword} />
              <FormControlLabel control={<Checkbox checked={this.state.showPassword} onChange={this.handleShowPassword} />} label="Mostrar senha" />
              <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.tryLogin}>Entrar</Button>
            </Box>
            <Snackbar className={classes.alertMessage} open={this.state.openClose} autoHideDuration={3000} onClose={this.handleClose} message={this.state.message} />

        </Container>

        <Backdrop className={classes.backdrop} open={this.state.openEdit}>
            <Paper>
              <Container component="main" maxWidth="xs" className={classes.paper}>
                  <Terms />
                <Box className={classes.form} method="post">
                  <Typography variant="caption"><Link to="./guide/Termo_Uso_eTesteDoPezinho_VOut2021.pdf" target="_blank">Termo de Uso - Sistema e-TesteDoPezinho</Link></Typography>
                  <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.updateAcceptTerms}>Li e aceito</Button>
                  <Button fullWidth variant="contained" className={classes.cancelButton} onClick={e => this.setState({ openEdit: false })}>Não aceito</Button>         
                </Box>
                <Snackbar className={classes.alertMessage} open={this.state.openClose} autoHideDuration={3000} onClose={e => this.setState({ openClose: false })} message={this.state.message} />
              </Container>
            </Paper>
        </Backdrop>

      </Container>
    )
  }
}
export default withStyles(useStyles)(EmployeeLogin);