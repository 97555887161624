import React from 'react';
import Home from './home';
// import FamilyLogin from './FamilyLogin' // Para desabilitar familia comentar essa linha
// import Family from './Family' // Para desabilitar familia comentar essa linha
import { Router, Route } from 'react-router-dom'
import { createMuiTheme, ThemeProvider, withStyles, responsiveFontSizes } from '@material-ui/core/styles';
import { cyan, purple } from '@material-ui/core/colors';
import EmployeeLogin from './EmployeeLogin';
import Header from './Header'
import Employee from './Employee';
import Admin from './Admin';
import Footer from './Footer';
import NewEmployee from './NewEmployee';
import { CssBaseline } from '@material-ui/core'
//import Privacy from './Privacy';
import Terms from './Terms';
import About from './About';
import { PrivateRoute } from './PrivateRoute';
import { history } from './services/history'
import { authenticationService } from './services/auth_service';
import { userService } from './services/user_functions';
import { userType } from './services/const'
//import Loading from './Loading'
import NotConnected from './NotConnected'
// import UpdatePassword from './UpdatePassword'; // Para desabilitar familia comentar essa linha
import UpdateEmployeePassword from './UpdateEmployeePassword';
import UpdateAdminPassword from './UpdateAdminPassword';
/*to do: alterar a forma de login: https://medium.com/better-programming/building-basic-react-authentication-e20a574d5e71 
https://medium.com/@ruthmpardee/passing-data-between-react-components-103ad82ebd17
https://jasonwatmore.com/post/2019/02/01/react-role-based-authorization-tutorial-with-example
*/
const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  main: {
    minHeight: '92vh',

    paddingBottom: '60px',
    padding: '27px'

  },
  container: {
    minHeight: '100%',
    position: 'relative',
  }
})



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      role: userType.NONE,
      connected: true,
    }
    userService.testConnection().catch(e => {
      this.setState({ connected: false, })
    })
  }

  //UNSAFE_componentWillMount() {
  componentWillMount() {
    authenticationService.currentUser.subscribe(x => {
      if (x && x.user_data) {
        this.setState({
          user: x.user_data,


        })
      }

    })

  }

  logout() {
    authenticationService.logout();
    history.push('/');
  }
  render() {

    let theme = createMuiTheme({
      //Para obter outras paletas de cores usar esse link https://material-ui.com/pt/customization/color/
      //Ele gera o código a ser usado. Abaixo cor lilás como teste oficialmente deixamos azul
      // palette: {
      //     primary: {main: '#c8a2c8',  },
      //     secondary: {main: '#00bcd4', },   },
      palette: {
        primary: { main: cyan[500], contrastText: "#fff" },
        secondary: { main: purple[500], contrastText: "#fff" }
      }

      });
    theme = responsiveFontSizes(theme);
    const { classes } = this.props;
    /*    <PrivateRoute path="/Familia"  role={userType.FAMILY}>
     {this.state.userData ? <Family currentUser={this.state.userData} /> : <Loading />}
    </PrivateRoute>*/
    return (
      <Router history={history}>

        <ThemeProvider theme={theme}>

          <CssBaseline />
          <div className={classes.container}>
            <Header userData={this.state.user} />
            <div className={classes.main}>
              <Route path="/" exact={true}  component={this.state.connected ? (() => <Home userData={this.state.user} />) : NotConnected} />
              {/* Para desabilitar familia comentar a linha abaixo 123- 125*/}
              {/* <Route path="/LoginFamilia" component={this.state.connected ?  (() => <FamilyLogin userData={this.state.user} />) : NotConnected} /> */}
              <Route path="/LoginFuncionario" component={this.state.connected ? (() => <EmployeeLogin userData={this.state.user} />) : NotConnected} />
              {/* Para desabilitar familia comentar a linha abaixo 123- 125*/}
              {/* <Route path="/AlterarSenha" component={this.state.connected ? (() => <UpdatePassword userData={this.state.user} />) : NotConnected} /> */}
              {/* <PrivateRoute path="/Familia" roles={userType.FAMILY} userData={this.state.user}
                component={this.state.connected ? Family : NotConnected} /> */}
              <PrivateRoute path="/Funcionario" role={userType.EMPLOYEE} userData={this.state.user}
                component={this.state.connected ? Employee : NotConnected} />
              <PrivateRoute path="/Admin" role={userType.ADMIN} userData={this.state.user} component={this.state.connected ? Admin : NotConnected} />
              <PrivateRoute path="/FuncionarioNovo" role={userType.ADMIN} userData={this.state.user} component={this.state.connected ? NewEmployee : NotConnected} />
              <PrivateRoute path="/AlterarSenhaFuncionario" role={userType.EMPLOYEE} userData={this.state.user} component={this.state.connected ? UpdateEmployeePassword : NotConnected} />
              <PrivateRoute path="/AlterarSenhaAdmin" role={userType.ADMIN} userData={this.state.user} component={this.state.connected ? UpdateAdminPassword : NotConnected} />

              <Route path="/Termos" component={this.state.connected ? Terms : NotConnected} />
              <Route path="/Sobre" component={this.state.connected ? About : NotConnected} />

            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </Router>

    );
  }
}
//<PrivateRoute path="/Privacidade" role={userType.FAMILY} userData={this.state.user} component={this.state.connected ? Privacy: NotConnected} />
export default withStyles(useStyles)(App);
