import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from './services/auth_service';
import { userType } from './services/const'

export const PrivateRoute = ({ component: Component, role, userData, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
        authenticationService.checkIfLogged().then(response => {
            if (response.status !== 200) authenticationService.logout();
        }).catch(e => authenticationService.logout())
        // check if route is restricted by role
        if (role && ((role === userType.ADMIN && !currentUser.user_data.is_superuser) || (role === userType.EMPLOYEE && !currentUser.user_data.is_staff))) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/' }} />
        }

        // authorised so return component
        return <Component userData={userData} {...rest}{...props} />
    }} />
)