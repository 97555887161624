import { BehaviorSubject } from 'rxjs';

import { apiPath } from './const';
import Axios from 'axios'
import { authHeader } from './auth_header';

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUserToken')));

export const authenticationService = {
    login,
    // loginFamilia, // Para desabilitar familia comentar essa linha
    loginFunc,
    logout,
    checkIfLogged,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};


function login(username, password) {
//return login message from API as promise
    return Axios.post(`${apiPath}/token2`, `grant_type=&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&scope=&client_id=&client_secret=`)
        .then(user => {
            sessionStorage.setItem('currentUserToken', JSON.stringify(user.data));
            currentUserSubject.next(user.data);
            return user;
        })
    /*
    OLD LOGIN IMPLEMENTATION
    return Axios.post(`${apiPath}/token`, `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`)
        .then(user => {
            Axios.get(`${apiPath}/users/me`,{headers: {Authorization: `Bearer ${user.data.access_token}`}}).then(user2 => {

                console.log(Object.assign(user.data, user2.data));
                sessionStorage.setItem('currentUserToken', JSON.stringify(Object.assign(user.data, user2.data)));
                currentUserSubject.next(Object.assign(user.data, user2.data));
                console.log(currentUserSubject.value)

            })
            return user;
        });*/
}

function loginFunc(username, password) {
    //return login message from API as promise from the employee endpoint
    return Axios.post(`${apiPath}/role_login/?staff_superuser=${true}`, `grant_type=&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&scope=&client_id=&client_secret=`)
        .then(user => {
            sessionStorage.setItem('currentUserToken', JSON.stringify(user.data));
            currentUserSubject.next(user.data);
            return user;
        })
}

// Para desabilitar familia comentar essa função
// function loginFamilia(username, password) {
//     //return login message from API as promise from the family endpoint
//     return Axios.post(`${apiPath}/role_login/?staff_superuser=${false}`, `grant_type=&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&scope=&client_id=&client_secret=`)
//         .then(user => {
//             sessionStorage.setItem('currentUserToken', JSON.stringify(user.data));
//             currentUserSubject.next(user.data);
//             return user;
//         })
// }
function checkIfLogged() {
    //check if current token is still accepted by API
    return Axios.get(`${apiPath}/users/token`, { headers: authHeader() })
}
function logout() {
    sessionStorage.removeItem('currentUserToken');
    currentUserSubject.next(null);
}
