import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = (theme) => ({
    main: {
        marginTop: theme.spacing(0),
    },
    typo: {
        marginTop: theme.spacing(1),
        //align: "right", 
        //não funciona align: 'justify',
    },
})

class Terms extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.main}>
              <Scrollbars style={{ width: 350, height: 400 }}>
                <Typography className={classes.typo} variant="h6">TERMO DE USO</Typography>
                <br />
                <Typography className={classes.typo}>Este instrumento contém os termos gerais, condições de acesso e uso dos serviços aplicáveis à utilização do sistema Teste do Pezinho Web disponibilizado pelo Serviço de Referência em Triagem Neonatal - SRTN/Unicamp através do website https://testedopezinho.fcm.unicamp.br.</Typography>

                <Typography className={classes.typo} variant="h6">&nbsp;&nbsp;&nbsp;&nbsp;1.Quem somos</Typography>
                <Typography>1.1 O Centro Integrado de Pesquisas Oncohematológicas na Infância (CIPOI) é um dos três Serviços de Referência em Triagem Neonatal do Estado de São Paulo, credenciados pelo Programa Nacional de Triagem Neonatal do Ministério da Saúde  para a realização do Teste do Pezinho e acompanhamento dos recém nascidos. 
                            A coleta do Teste do Pezinho do seu bebê é realizada após 48 horas de vida e encaminhada para o nosso Laboratório, onde serão processadas para a detecção de 6 doenças preconizadas pelo Ministério da Saúde: Hemoglobinopatias, Fenilcetonúria, Hipotireoidismo Congênito, Fibrose Cística, Biotinidase e Hiperplasia Adrenal Congênita. 
                            Nossa equipe entrará em contato com a família para eventual recoleta ou acompanhamento ambulatorial dos recém nascidos com exames alterados.
                </Typography>
                <Typography className={classes.typo} variant="h6">&nbsp;&nbsp;&nbsp;&nbsp;2.Uso do Website e Aceitação das Condições de Uso</Typography>
                <Typography>2.1 O uso dos serviços do website é regido por este Termo de Uso de Software, que você deve ler atentamente antes de utilizar o sistema.</Typography>
                <Typography>2.2 Ao registrar-se, acessar e utilizar o sistema Teste do Pezinho Web de qualquer forma, incluindo navegação, visualização, download do resultado de exame, geração, recebimento e transmissão de quaisquer dados, informações ou mensagens, você manifesta sua expressa concordância com este Termo e se compromete a respeitar e cumprir todas as disposições aqui contidas.</Typography>
                <Typography>2.3 Se você não concorda com este Termo de Uso de Software, não utilize este Website..</Typography>
                <Typography>2.4 Ao selecionar o botão “Li e aceito, você manifestará eletronicamente sua aceitação às condições deste termo.</Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;3.Acesso ao Website e aos Serviços</Typography>
                <Typography>3.1 Este software por ser acessado por computadores ou smartphones. Para smartphones Android disponivel através do navegador Google Chrome, para IOS através do Safari.</Typography> 
                <Typography>3.2 Não nos responsabilizamos por quaisquer dificuldades técnicas que você enfrente decorrentes do uso destes softwares e hardwares.</Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;4.Processo de Cadastro</Typography>
                <Typography><u>Família do Paciente:</u></Typography>
                <Typography>4.1 No momento da coleta do Teste do Pezinho de seu bebê são solicitadas  informações necessárias para a realização do Teste do Pezinho, entre elas o número de telefone celular da família. Através desse número de celular, a família receberá um SMS informativo contendo o endereço e a senha de acesso ao website para obter o resultado do exame de seu bebê.</Typography>
                <Typography>4.2 De posse da senha e endereço do website, a família pode acessar o sistema via navegador, através do celular ou computador.</Typography>
                <Typography>4.3 Para efetuar login, informar o CPF da mãe (o qual foi previamente informado no ato da coleta da amostra de sangue através do cartão de coleta), e a senha recebida via SMS.</Typography>
                <Typography>4.4 Após efetuar o login no sistema, a família poderá realizar o download de resultados de exames do Teste do Pezinho de seu bebê.</Typography>
                <Typography><u>Instituições:</u> Os Hospitais e Postos de Coleta que fazem parte da abrangência do SRTN/Unicamp, à medida que forem treinados para uso deste sistema, receberão um login e senha de acesso ao website. </Typography>
                <Typography>4.5 Através do login e senha previamente recebidos pelo SRTN/Unicamp, o funcionário da instituição poderá efetuar login no sistema, realizar buscas e download de resultados e trocar a senha de acesso.</Typography>

                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;5.Base Legal</Typography>
                <Typography>5.1 Este termo de uso foi elaborado em conformidade com as disposições gerais da</Typography> 
                <Typography><a target="_blank" rel="noopener noreferrer" href='https://www.in.gov.br/materia/-/asset_publisher/Kujrw0TZC2Mb/content/id/36849373/do1-2018-08-15-lei-no-13-709-de-14-de-agosto-de-2018-36849337'>
                    Lei No 13.709, DE 14 DE AGOSTO DE 2018, denominada LGPD;</a></Typography>
                <Typography>5.2 No que diz respeito ao tratamento de dados pessoais este termo de uso está de acordo com o Artigo 7º da LGPD e de acordo com o Artigo 23º, que diz respeito aos dados pessoais pelo poder público.</Typography>
                <Typography>5.3 Como está previsto em Lei através da</Typography>
                <Typography><a target="_blank" rel="noopener noreferrer" href='http://bvsms.saude.gov.br/bvs/saudelegis/gm/2001/prt0822_06_06_2001.html'>
                    Portaria Nº 822, de 06 de junho de 2001 - Ministério da Saúde</a>, no Anexo II item 1, todo recém-nascido tem direito à realização de testes de Triagem Neonatal. Portanto, ao coletar a amostra também são coletadas as informações necessárias pessoais da mãe e do bebê para a realização do Teste do Pezinho.</Typography>

                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;6.Como utilizamos suas informações pessoais</Typography>
                <Typography>6.1 As informações pessoais serão usadas para fornecer o resultado do Teste do Pezinho do seu filho. Para garantir a autenticação e privacidade dos seus dados pessoais somente você ou um funcionário do Hospital ou Posto de Coleta terão acesso ao resultado do Teste do Pezinho.</Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;7.Informações necessárias para Acessar o Teste do Pezinho</Typography>
                <Typography>Os dados necessários para utilização dos serviços deste website são:</Typography>
                <Typography>7.1 <u>Acesso de famílias</u>:</Typography>
                <Typography>CPF da mãe da criança;
                Senha enviada para o celular através de SMS;
                No caso de esquecimento de senha, será solicitada à família a confirmação de alguns dados como: 
                CPF da mãe
                CNS - Cartão Nacional de Saúde da criança;
                DNV - Declaração de Nascido Vivo;
                Data de nascimento da criança.
                </Typography>
                <Typography>7.2 <u>Acesso de Instituições</u>: As instituições poderão realizar buscas aos resultados do Teste de Pezinho através das informações abaixo e assim realizar o download do resultado do Teste do Pezinho da criança:</Typography>
                Nome da mãe;
                Sobrenome da mãe;
                Local de Coleta do teste do Pezinho;
                DNV - Declaração de Nascido Vivo;
                CNS - Cartão Nacional de Saúde da criança;
                Data de Nascimento da criança;
                Data de Coleta da amostra do Teste do Pezinho.
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;8.Nossas Obrigações</Typography>
                <Typography>8.1 Manter o serviço deste website disponível para todas as famílias que realizaram a coleta do Teste do Pezinho em Hospitais e Postos de Coleta do SUS que compreendem a abrangência do SRTN/Unicamp e que forneceram no ato da coleta o CPF da mãe e telefone celular.</Typography>
                <Typography>8.2 Manter o serviço deste website disponível para os funcionários de Hospitais e Postos de Coleta do SUS que compreendem a abrangência do SRTN/Unicamp, responsáveis pela entrega do Teste do Pezinho às famílias.</Typography>
                <Typography>8.3 Manter o Teste do Pezinho disponível através deste sistema pelo prazo de um ano,  a contar da data de emissão do resultado. Após essa data, caso necessário a família ou Instituição deverá entrar em contato com o Laboratório de Triagem Neonatal no CIPOI para obtenção do Teste do Pezinho.</Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;9.Suas obrigações</Typography>
                <Typography><u>Família do paciente</u>:</Typography>
                <Typography>9.1 No ato da coleta da amostra, informar os dados necessários para a realização do exame. Sem o CPF e número de celular informados corretamente não será possível acesso ao sistema.</Typography>
                <Typography>9.2 Utilizar o serviço deste website unicamente para obter o resultado do Teste do Pezinho de seu bebê.</Typography>
                <Typography>9.3 Não informar seu login e senha para terceiros.</Typography>
                <Typography>9.4 Informar o SRTN/Unicamp ou Posto de Coleta mais próximo de sua casa, no caso de mudança de telefone celular.</Typography>
                <Typography>9.5 Efetuar logoff ao final de utilização dos serviços deste website.</Typography>
                <Typography><u>Instituições</u>:</Typography>
                <Typography>9.6 Identificar a família que está solicitando o resultado do Teste do Pezinho através de um documento (Certidão de Nascimento, ou RG) que comprove a responsabilidade de tutela sobre a criança que realizou o Teste do Pezinho.</Typography>
                <Typography>9.7 Utilizar o serviço deste website unicamente para obter resultados de Testes do Pezinho e disponibilizar às famílias e/ou profissionais da saúde que necessitem visualizá-lo.</Typography>
                <Typography>9.8 Cada instituição terá um login e senha únicos e compartilháveis entre os funcionários da mesma instituição responsáveis por consultar os resultados do Teste do Pezinho.</Typography>
                <Typography>9.9 Não informar seu login e senha para terceiros.</Typography>
                <Typography>9.10 Informar o SRTN/Unicamp, no caso de mudança de telefone celular da família.</Typography>
                <Typography>9.11 Efetuar logoff ao final de utilização dos serviços deste website.</Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;10.Confidencialidade e Proteção de Dados</Typography>
                <Typography>10.1 Cada parte se compromete a manter e tratar como confidencial e não revelar a terceiros qualquer Informação Confidencial relacionada ao Sistema e aos serviços, dados de usuários, ou usar informações para qualquer propósito que não aquele previsto no presente Termo de Uso.</Typography>
                <Typography>10.2 De acordo com o <a target="_blank" rel="noopener noreferrer" href='http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13787.htm'>
                    Artigo 6º da Lei 13.787, de 27 de dezembro de 2018</a>
                    , que dispõe sobre a digitalização e a utilização de sistemas informatizados para a guarda, o armazenamento e o manuseio de prontuário de paciente, suas informações pessoais são armazenadas, por no mínimo 20 anos, em registros eletrônicos e físicos mantidos por nós e/ou nossos provedores de serviços.</Typography>    
                <Typography>10.3 A segurança das informações de identificação pessoal associadas à você é tratada confidencialmente. Usamos várias medidas de segurança para proteger contra a perda, uso não autorizado e corrupção de qualquer Informação Pessoal que esteja sob nosso controle. Nós revisamos regularmente as nossas políticas de segurança e de privacidade. </Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;11.Alterações para esse termo</Typography>
                <Typography>11.1 Reservamos o direito de modificar esse documento a qualquer momento, então é recomendável que você revise-o periodicamente. Eventuais modificações serão publicadas neste website e você deverá ler e aceitar novamente os termos para continuar utilizando os serviços.</Typography>
                <Typography className={classes.typo} variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;12.Contato</Typography>
                <Typography>12.1 Contato, perguntas, comentários e solicitações relacionadas a este Termo de Uso devem ser endereçados a: cipoi@unicamp.br</Typography>

              </Scrollbars>
            </Container>);
    }
}

export default withStyles(useStyles)(Terms);