import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
    footer: {
        padding: theme.spacing(2, 2),
        //marginTop: theme.spacing(1),
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '50px',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
});

class Footer extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <footer className={classes.footer}>
                <Typography variant="h7" noWrap>Serviço de Referência em Triagem Neonatal - SRTN/Unicamp </Typography>
            </footer>
        )
    }
}
export default withStyles(useStyles)(Footer);