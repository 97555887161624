import React from 'react';
import { AppBar, Typography, Box, Toolbar, Menu, MenuItem, Button, Grid, Avatar } from "@material-ui/core";
import LogoPNG from './logos/LogoPNG.png'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { authenticationService } from './services/auth_service';
import { history } from './services/history'

const useStyles = (theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '6vh',
        alignItems: 'center',
        justify: 'space-between'
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1)
    }
});
class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openClose: false,
            anchorEl: null,
        }
    }

    render() {
        if (this.props.userData !== null) {
            return (
                <div>
                    <Button style={{ color: '#ffffff' }} aria-controls="menu-login" aria-haspopup="true" 
                        onClick={e => this.setState({ openClose: true, anchorEl: e.currentTarget })} >
                        <Avatar className={this.props.classes.avatar} />
                        {(!this.props.userData.is_superuser && !this.props.userData.is_staff) && this.props.userData.cpf}
                        {this.props.userData.is_staff && this.props.userData.name}
                    </Button>
                    <Menu open={this.state.openClose} onClose={e => this.setState({ openClose: false })} anchorEl={this.state.anchorEl}>
                        {this.props.userData.is_superuser && <MenuItem onClick={e => { history.push("/Admin") }}>Página de Administrador</MenuItem>}
                        {this.props.userData.is_staff && <MenuItem onClick={e => { history.push("/Funcionario") }}>Página de Funcionário</MenuItem>}
                        {this.props.userData.is_staff && (!this.props.userData.is_superuser) && <MenuItem onClick={e => { history.push("/AlterarSenhaFuncionario") }}>Trocar senha</MenuItem>}
                        {this.props.userData.is_staff && this.props.userData.is_superuser && <MenuItem onClick={e => { history.push("/AlterarSenhaAdmin") }}>Trocar senha</MenuItem>}
                        {(!this.props.userData.is_superuser && !this.props.userData.is_staff) && <MenuItem onClick={e => { history.push("/Familia") }}>Página de Familia</MenuItem>}
      
                        {<MenuItem onClick= {e => { history.push("/Sobre")}}>Sobre</MenuItem>}
                        <MenuItem onClick={e => { authenticationService.logout(); history.push("/") }}>Sair</MenuItem>
                    </Menu>
                </div>
            )
        }
        else return (<div />);
    }
}


class Header extends React.Component {

    render() {
        const { classes } = this.props
        return (
            <AppBar position="sticky" style={{ margin: 0 }}>

                <Toolbar >
                    <Grid container direction="row"
                        justify="space-between"
                        alignItems="center" className={classes.header} >
                        <Grid item><Box marginLeft="6px" style={{ display: 'flex' }}>
                            <Link to="/"><img src={LogoPNG} width="30px" height="30px" alt="" /></Link>
                            <Typography variant="h5" >Resultado do Teste do Pezinho</Typography></Box></Grid>
                        <Grid item><UserMenu userData={this.props.userData} classes={classes} /></Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

        );
    }
}
export default withStyles(useStyles)(Header);
