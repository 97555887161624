
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Container, TextField, Button } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { userService } from './services/user_functions';
import Loading from './Loading';
import { authenticationService } from './services/auth_service';
//import { Link } from 'react-router-dom'
//import PDFViewer from 'pdf-viewer-reactjs'

/* ESSA PARTE DO CÓDIGO FOI TIRADA DE https://material-ui.com/components/tables/ COM ALTERAÇÕES */






function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'prMotherFirstname', numeric: false, disablePadding: false, label: 'Nome da mãe' },
  { id: 'prMotherLastname', numeric: false, disablePadding: false, label: 'Sobrenome da mãe' },
  { id: 'HospitalNasc', numeric: false, disablePadding: false, label: 'Hospital Nascimento' },
  { id: 'DataNasc', numeric: false, disablePadding: false, label: 'Data nascimento' },
  { id: 'DNV', numeric: false, disablePadding: false, label: 'DNV' },
  { id: 'CNS', numeric: false, disablePadding: false, label: 'CNS' },
  { id: 'ptnFirstname', numeric: false, disablePadding: false, label: 'Nome da criança'},
  { id: 'ptnSurname', numeric: false, disablePadding: false, label: 'Sobrenome da criança'},  
  { id: 'LocalColeta', numeric: false, disablePadding: false, label: 'Local coleta' },
  { id: 'DataColeta', numeric: false, disablePadding: false, label: 'Data coleta' },
  { id: 'CPF', numeric: false, disablePadding: false, label: 'CPF da mãe' },
  { id: 'PDF_ImageDate', numeric: false, disablePadding: false, label: 'Data Emissão' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell padding='checkbox'></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cell: {
    whiteSpace: 'nowrap'

  }
}));

function EnhancedTable(props) {
  const { rows } = props;
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('ordering');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  if (rows === []) return (<Loading />)
  else {
    const handleRequestSort = (event, property) => {

      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);

    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const downloadFile = (PDF_Filename, nomeMae, sobrenomeMae) => {
      // if (
      //   /CriOS/i.test(navigator.userAgent) &&
      //   /iphone|ipod|ipad/i.test(navigator.userAgent)
      // ) {
      //   // userService.downloadFile(this.props.child.PDF_Filename).then(response => {
      //   userService.downloadFile(PDF_Filename).then(response => {
      //       const fileURL = URL.createObjectURL(new Blob([response.data, 'TesteDoPezinho.pdf'], {type: 'application/pdf'}))
      //       window.open(fileURL, 'TesteDoPezinho.pdf')

      // //   }).catch(e => window.alert("Não foi possível obter o arquivo."))
      // } else {
        const FileDownload =  require('js-file-download');
        userService.downloadFile(PDF_Filename).then(response => {
            //FileDownload(response.data, `resultado_${nomeMae}${sobrenomeMae}_${PDF_Filename}.pdf`);
            FileDownload(response.data, PDF_Filename);
        }).catch(e => window.alert("Não foi possível obter o arquivo."))
    // }
  }



    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.IDExport);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.IDExport)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.IDExport}
                        selected={isItemSelected}
                      >

                        <TableCell className={classes.cell} padding="checkbox">
                          <Button onClick={() => downloadFile(row.PDF_Filename, row.prMotherFirstname, row.prMotherSurname)}><GetAppIcon  /></Button>


                        </TableCell>
                        <TableCell className={classes.cell} align="left">{row.prMotherFirstname}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.prMotherSurname}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.HospitalNasc}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.DataNasc ===  null ? '' : new Date(row.DataNasc).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</TableCell>
                        <TableCell component="th" className={classes.cell} align="left">{row.DNV}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.CNS}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.ptnFirstname}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.ptnSurname}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.LocalColeta}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.DataColeta ===  null ? '' : new Date(row.DataColeta).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</TableCell>
                        <TableCell className={classes.cell} align="left">{row.CPF}</TableCell>
                        <TableCell className={classes.cell} align="right">{row.PDF_ImageDate ===  null ? '' : new Date(row.PDF_ImageDate).toLocaleDateString("pt-BR", { timeZone: 'UTC' })}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination labelDisplayedRows={({ from, to, count }) => `Mostrando ${from}-${to} de ${count} itens.`}
            labelRowsPerPage="Itens por página:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <Typography>Os Testes do Pezinho estão disponíveis através deste web site pelo prazo de 1 ano a partir da emissão do resultado.</Typography>
        {/* <Typography>Exames de investigação familiar para Hemoglobinopatias não estão disponíveis através desta plataforma.</Typography> */}

      </div>
    );
  }
}

const useStyles2 = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },

  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  filtrosHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

  },
  table: {
    width: '100%',
    minWidth: 700,
    alignItems: 'center',
  },
  tableitem: {
    width: '18%',
  },
  tableitem_m: {
    width: '10%',
  }
});

/* FIM DA PARTE IMPORTADA*/

class Employee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: this.props.userData,
      rows: [],
      cns: '',
      cpf: '',
      dnv: '',
      firstName: '',
      lastName: '',
      birthday: '',
      birthPlace: '',
      collPlace: '',
      collDay: '',
      // CodHNasc: '',
      CodLCol: '',
      PDF_ImageDate: '',

    }
  }

  limparFiltros = () => {

    document.getElementById('dnv').value = ""
    document.getElementById('cns').value = ""
    document.getElementById('cpf').value = ""
    document.getElementById('firstName').value = ""
    document.getElementById('lastName').value = ""
    document.getElementById('birthday').value = ""
    document.getElementById('birthPlace').value = ""
    document.getElementById('collPlace').value = ""
    document.getElementById('collDay').value = ""
    // document.getElementById('CodHNasc').value = ""
    document.getElementById('CodLCol').value = ""
    document.getElementById('PDF_ImageDate').value = ""

    setTimeout(() => {
      this.setState({
        dnv: '',
        cns: '',
        cpf: '',
        firstName: '',
        lastName: '',
        birthday: '',
        birthPlace: '',
        collPlace: '',
        collDay: '',
        // CodHNasc: '',
        CodLCol: '',
        PDF_ImageDate: '',
        });
       this.listResults();
     }, 10)
   }

  listResults = () => {


    userService.listResults(this.state.cns, this.state.cpf, this.state.dnv, this.state.firstName, this.state.lastName, this.state.birthday
      , this.state.birthPlace, this.state.collPlace, this.state.collDay, this.state.CodLCol, this.state.PDF_ImageDate).then(response => {
      // , this.state.birthPlace, this.state.collPlace, this.state.collDay, this.state.CodHNasc, this.state.CodLCol).then(response => {

        this.setState({ rows: response.data });

      }
      ).catch(e => {
        window.alert("A sua sessão expirou. Entre com seus dados novamente.")
        authenticationService.logout();
        this.props.history.push("/")
      });
  }
  componentDidMount() {
    // this.listResults()
    if (this.props.userData.is_staff && (!this.props.userData.is_superuser)){ //funcionario LocalColeta
      this.listResults(this.state.CodLCol= this.props.userData.login )
    }
    else if(this.props.userData.is_staff && this.props.userData.is_superuser){ //Admin
      this.listResults()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Container >
        {/* <Typography variant="h6" className={classes.grid}>Bem vindo(a), {this.props.userData.name}</Typography> */}
        <Container maxWidth="lg">
          <ExpansionPanel className={classes.grid}>
            <ExpansionPanelSummary className={classes.filtrosHeader}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant='button' className={classes.heading}><b>Filtros</b></Typography>
            </ExpansionPanelSummary>
            <TableContainer>
              <table className={classes.table}>
                <tbody>
                <tr><td align="right" className={classes.tableitem}><Typography >Nome da mãe:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'firstName' onChange={e => this.setState({ firstName: e.target.value })} inputProps={{ maxLength: 100 }}  /></td>
                  <td align="right" className={classes.tableitem}><Typography >Data de emissão:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'PDF_ImageDate' type="date" onChange={e => this.setState({ PDF_ImageDate: (e.target.value === '' ? '' : encodeURIComponent(new Date(e.target.value).toLocaleDateString("pt-BR", { timeZone: 'UTC' }))) })}/> </td>
                  <td align="right" className={classes.tableitem}><Typography >CPF da mãe:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'cpf' onChange={e => this.setState({ cpf: e.target.value })} inputProps={{ maxLength: 11 }}/></td>
                </tr>
                <tr><td align="right" className={classes.tableitem}><Typography >Último sobrenome da mãe:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'lastName' onChange={e => this.setState({ lastName: e.target.value })} inputProps={{ maxLength: 100 }}/></td>
                  <td></td><td></td>
                  <td align="right" className={classes.tableitem}><Typography > DNV:</Typography></td>
                  <td className={classes.tableitem} ><TextField id = 'dnv' onChange={e => this.setState({ dnv: e.target.value })} inputProps={{ maxLength: 13 }}/></td>
                </tr>
                <tr><td align="right" className={classes.tableitem}><Typography >Hospital de Nascimento:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'birthPlace' onChange={e => this.setState({ birthPlace: e.target.value })} inputProps={{ maxLength: 100 }}/></td>
                  <td></td><td></td>
                  {/* <td align="right" className={classes.tableitem_m}><Typography >Cód. Hosp Nasc:</Typography></td>
                  <td className={classes.tableitem_m}><TextField id = 'CodHNasc' onChange={e => this.setState({ CodHNasc: e.target.value })} inputProps={{ maxLength: 5 }}/></td> */}
                  <td align="right" className={classes.tableitem}><Typography >CNS:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'cns' onChange={e => this.setState({ cns: e.target.value })} inputProps={{ maxLength: 15 }}/></td>
                </tr>
                <tr><td align="right" className={classes.tableitem}><Typography >Local de coleta:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'collPlace' onChange={e => this.setState({ collPlace: e.target.value })} inputProps={{ maxLength: 100 }}/></td>
                  <td align="right" className={classes.tableitem_m}><Typography >Cód. Loc Coleta:</Typography></td>
                  <td className={classes.tableitem_m}><TextField id = 'CodLCol'  defaultValue={this.props.userData.login} onChange={e => this.setState({ CodLCol: e.target.value })} inputProps={{ maxLength: 5 }}/></td>
                  <td align="right" className={classes.tableitem}><Typography >Data de nascimento:</Typography></td>
                  <td className={classes.tableitem}><TextField id = 'birthday' type="date" onChange={e => this.setState({ birthday: (e.target.value === '' ? '' : encodeURIComponent(new Date(e.target.value).toLocaleDateString("pt-BR", { timeZone: 'UTC' }))) })} /></td>
                </tr>
                  <tr><td></td><td></td>
                    <td></td><td></td>
                    <td align="right" className={classes.tableitem}><Typography >Data da coleta:</Typography></td>
                    <td className={classes.tableitem}><TextField id = 'collDay' type="date" onChange={e => this.setState({ collDay: (e.target.value === '' ? '' : encodeURIComponent(new Date(e.target.value).toLocaleDateString("pt-BR", { timeZone: 'UTC' }))) })} /></td>
                </tr>
                <tr>
                  <td colSpan="1" align="right"><Button variant="contained" onClick={this.listResults}>FILTRAR</Button></td>
                  <td colSpan="2" align="right"><Button variant="contained" onClick={this.limparFiltros}>LIMPAR BUSCA</Button></td>
                </tr>
                </tbody>
              </table>
            </TableContainer>
          </ExpansionPanel>
          <EnhancedTable rows={this.state.rows} />
        </Container>
      </Container>
    )
  }
}

export default withStyles(useStyles2)(Employee);

