import React from 'react';
import { Container, Typography, Table, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

const useStyles = (theme) => ({
    main: {
        marginTop: theme.spacing(8),
    },
    typo: {
        marginTop: theme.spacing(1)
    },
    tableitem: {
        with: "50%"
    }

})

class About extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.main}>

                <Typography className={classes.typo} variant="h5">Quem Somos</Typography>
                <br /><br /><br />
                <Typography>O Centro Integrado de Pesquisas Oncohematológicas na Infância (CIPOI) é um dos três Serviços de Referência em Triagem Neonatal do Estado de São Paulo, credenciados pelo Programa Nacional de Triagem Neonatal do Ministério da Saúde  para a realização do Teste do Pezinho e acompanhamento ambulatorial dos recém nascidos com exames alterados.</Typography>
                <Typography>O Serviço de Referência em Triagem Neonatal - SRTN/Unicamp oferece através da plataforma web, Android e IOS o Sistema e-TesteDoPezinho que possibilita a consulta de resultados do Teste do Pezinho processados no Laboratório de Triagem Neonatal da Unicamp.</Typography> <br />
                <Table>
                    <TableCell><Typography variant="h8"><p>Desenvolvimento: <br />
                Equipe de TI CIPOI/FCM/Unicamp</p><br/>
                Agradecimento aos alunos participantes do projeto:<br />
                Orientação: Prof. Paulo Licio de Geus - Instituito de Computação da Unicamp<br />
                <p>Ana Clara Zoppi Serpa<br />
                Felipe Videira Rodrigues<br />
                Luara Peres Oliveira da Silva<br />
                Luiz Miguel Cerqueira <br /></p>
                <br />
                </Typography>
                </TableCell>
                

                <Typography variant="caption"><Link to="./guide/Termo_Uso_eTesteDoPezinho_VOut2021.pdf" target="_blank">Termo de Uso - Sistema e-TesteDoPezinho</Link></Typography>
                <br />
                <Typography variant="caption"><Link to="./guide/ManualUsuario_Inst.pdf" target="_blank">Manual do usuário</Link></Typography>
                </Table>
            </Container>);
    }
}

export default withStyles(useStyles)(About);